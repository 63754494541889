.content {
    width: auto;
    margin-top: 15px;
}
.content-header {
    background: #878686;
    font-weight: bold;
}

.content-body {
    background: #f8f8f8;
}



.amenities-image {
    width: 100px;
}

.amenities {
    padding-left: 0px;
}
.particulars-row {
    padding-bottom: 3px;
}
.particular-label {
    text-align: right;
    font-weight: bold;
}
.amenity-name {
    font-weight: bold;
    font-size: large;
}
.particular-label2 {
    padding-left: 0px;
}

.particular-input {
    padding-left: 0px;
    padding-right: 0px;
}

.amount-input {
    padding-left: 0px;
}

div.card-body {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

.payment-total-section-label {
    font-size: small;
}