.loginBackGround {
  background: url('../../assets/img/jungle-safari-login.jpg') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
form {
  background-color: rgb(197, 194, 194, 0.4);
  padding: 20px;
  border-radius: 10px;
  color: white;
}
.nav-link {
  color: white;
}
