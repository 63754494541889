.content {
    width: auto;
    margin-top: 15px;
}
.content-header {
    background: #878686;
    font-weight: bold;
}

.content-body {
    background: #f8f8f8;
}



.amenities-image {
    width: 100px;
}

.amenities {
    padding-left: 0px;
}
.particulars-row {
    padding-bottom: 3px;
}
.particular-label {
    text-align: right;
    font-weight: bold;
}
.amenity-name {
    font-weight: bold;
    font-size: large;
}
.particular-label2 {
    padding-left: 0px;
}

.particular-input {
    padding-left: 0px;
    padding-right: 0px;
}

.amount-input {
    padding-left: 0px;
}

.amount-alignment {
    text-align: end;
}


.inner-addon {
    position: relative;
  }
  
  /* style glyph */
  .inner-addon .glyphicon {
    position: absolute;
    padding: 7px;
    pointer-events: none;
    background-color: #d8d7d7;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  
  /* align glyph */
  .left-addon .glyphicon  { left:  0px;}
  .right-addon .glyphicon { right: 0px;}
  
  /* add padding  */
  .left-addon input  { padding-left:  30px; }
  .right-addon input { padding-right: 60px; }
  